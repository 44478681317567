import axios from 'axios';
import router from '@/router';
import {ElMessage} from "element-plus"; // 导入 router 实例

// 创建新的axios实例
const service = axios.create({
    // 设置请求超时时间，单位为毫秒
    timeout: 5000,
});

// axios请求拦截器
service.interceptors.request.use(
    config => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `wei ${token}`;
        }
        config.headers['User-Type'] = 'backstage';
        return config;
    },
    error => Promise.reject(error)
);

// 刷新 Token 的函数
async function refreshToken() {
    try {
        const response = await service({
            url: '/api/auth/authorize',
            method: 'post',
            data: {
                loginType: 'refreshToken',
                refreshToken: localStorage.getItem('refreshToken')
            }
        });
        if (response.data.code === 200) {
            localStorage.setItem('accessToken', response.data.data.accessToken);
            localStorage.setItem('refreshToken', response.data.data.refreshToken);
            localStorage.setItem('user', JSON.stringify(response.data.data.user));
            return response.data.data.accessToken;
        } else {
            localStorage.clear(); // 清除所有缓存信息
            await router.push('/login');
            return null; // 返回 null 表示刷新失败
        }
    } catch (error) {
        localStorage.clear(); // 清除所有缓存信息
        await router.push('/login');
        return null; // 返回 null 表示刷新失败
    }
}


// axios响应拦截器
service.interceptors.response.use(
    response => {
        // 响应成功的处理逻辑
        const {data} = response;
        if (data.code === 'xxx') {
            console.log('特定操作：响应码为 xxx');
        }
        return response;
    },
    async error => {
        // 响应失败的处理逻辑
        const {response, config} = error;
        // 获取请求 URL
        if (config.url === "/api/auth/authorize") {
            if (response.status === 401) {
                ElMessage({
                    message: "请求未授权",
                    type: "error"
                })
                localStorage.clear(); // 清除所有缓存信息
                await router.push('/login')
            }
        }
        if (response) {
            // 根据 HTTP 状态码进行不同的错误处理
            switch (response.status) {
                case 401:
                    // 尝试刷新 Token
                    const newToken = await refreshToken();
                    if (newToken) {
                        // 更新请求头中的 Token
                        error.config.headers.Authorization = `wei ${newToken}`;
                        // 重新发起原始请求
                        return service.request(error.config);
                    } else {
                        localStorage.clear(); // 清除所有缓存信息
                        await router.push('/login')
                        break;
                    }
                case 403:
                    console.log('拒绝访问，权限不足');
                    break;
                case 500:
                    console.log('服务器错误');
                    break;
                default:
                    console.log(`请求错误，状态码：${response.status}`);
            }
        }
        // 即使出错也直接放行，返回错误信息
        return Promise.resolve(error.response ? error.response : error);
    }
);

// 处理 GET 和 POST 请求的通用方法
const api = (options = {}) => {
    const {url, method = 'get', params = {}, data = {}} = options;
    return new Promise((resolve) => {
        service({
            url,
            method,
            params: method.toLowerCase() === 'get' ? params : undefined, // 仅在 GET 请求时使用 params
            data: method.toLowerCase() === 'post' ? data : undefined, // 仅在 POST 请求时使用 data
        })
            .then(res => {
                // 请求成功返回后端数据
                resolve(res.data);
            })
            .catch(err => {
                // 请求失败时也放行，返回错误信息
                resolve(err);
            });
    });
};


export default api;
