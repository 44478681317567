<template>
  <div class="login-container">
    <div class="login">
      <div class="title">后台管理系统</div>
      <div class="from">
        <div class="from-input">
          <el-input v-model="loginForm.account" placeholder="请输入用户名">
            <template #prepend>
              <User style="width: 1.2em; height: 1.2em;"/>
            </template>
          </el-input>
        </div>
        <div class="from-input">
          <el-input v-model="loginForm.password" :type="view ? '' : 'password' " placeholder="请输入密码">
            <template #prepend>
              <Lock style="width: 1.2em; height: 1.2em;"/>
            </template>
            <template #append>
              <Hide style="width: 1.2em; height: 1.2em;" @click=" view = true" v-if="!view"/>
              <View style="width: 1.2em; height: 1.2em;" @click=" view = false" v-if="view"/>
            </template>
          </el-input>
        </div>
        <el-button class="el-empty__bottom" type="primary" @click="login">登录</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import {authLogin} from "@/api/pages/login"
import {ElMessage} from "element-plus";

export default {
  name: "login",
  data() {
    return {
      view: false,
      loginForm: {
        refreshToken: "admin",
        account: "",
        password: "",
      }
    };
  },
  methods: {
    login() {
      authLogin(this.loginForm).then(res => {
        if (res.code === 200) {
          localStorage.setItem("accessToken", res.data.accessToken)
          localStorage.setItem("refreshToken", res.data.refreshToken)
          localStorage.setItem("user", JSON.stringify(res.data.user))
          ElMessage({
            message: "登录成功",
            type: "success"
          })
          this.$router.push('/index');
        } else {
          ElMessage({
            message: res.msg,
            type: 'error',
          })
        }

      })
    }
  }
};
</script>

<style lang="scss">
.login-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  //background: url("@/assets/images/login.png") 0 bottom ;
  background: 0 bottom repeat-x #049ec4;
  animation: animate-cloud 20s linear infinite;

  .login {
    text-align: center;
    margin: 0 auto;
    width: 500px;
    height: 300px;
    box-shadow: -4px 5px 10px rgba(0, 0, 0, 0.4);
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fff;

    .title {
      margin-top: 60px;
      text-align: center;
      margin-bottom: 8px;
      color: #333;
      font-weight: 300;
      letter-spacing: 2px;
      font-size: 25px;
    }

    .from {
      padding: 5px;

      .from-input {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .el-input {
          margin: 10px;
          width: 60%;
        }
      }

      .el-empty__bottom {
        margin: 10px;
        width: 60%;
      }
    }
  }
}
</style>
