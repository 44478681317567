import api from '@/apiAxios';

export const authLogin = (row) => {
    return api({
        url: '/api/auth/authorize',
        method: 'post',
        data: row
    })
}

