<template>
  <div>
    <template v-for="item in items" :key="item.id">
      <!-- 处理有子菜单的情况 -->
      <el-sub-menu v-if="item.children && item.children.length > 0" :index="item.id" class="menu-item-div">
        <template #title>
          <component :is="getIconComponent(item.icon)" :size="20" class="icon"/>
          <el-tooltip :content="item.menuName" placement="right">
            <span class="menu-name">{{ item.menuName }}</span>
          </el-tooltip>
        </template>
        <recursive-menu :dynamicTags="dynamicTags" :items="item.children"/>
      </el-sub-menu>

      <!-- 处理没有子菜单的情况 -->
      <el-menu-item
          v-else
          :index="item.id"
          @click="handleMenuClick(item)"
          class="menu-item-div"
      >
        <component :is="getIconComponent(item.icon)" :size="20" class="icon"/>
        <el-tooltip :content="item.menuName" placement="right">
          <span class="menu-name">{{ item.menuName }}</span>
        </el-tooltip>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';

export default defineComponent({
  name: 'RecursiveMenu',
  props: {
    items: {
      type: Array,
      required: true
    },
    dynamicTags: {
      type: Array,
      required: true
    },
  },
  methods: {
    getIconComponent(iconName) {
      return ElementPlusIconsVue[iconName] || null;
    },
    handleMenuClick(item) {
      // 先将 dynamicTags 数组中所有的 checked 设置为 false
      this.dynamicTags.forEach(tag => tag.checked = false);
      // 检查 dynamicTags 数组中是否已经包含此项
      const existingTagIndex = this.dynamicTags.findIndex(tag => tag.id === item.id);
      if (existingTagIndex !== -1) {
        // 如果存在，则将其 checked 设置为 true
        this.dynamicTags[existingTagIndex].checked = true;
      } else {
        // 如果不存在，则添加，并设置当前 item 的 checked 为 true
        item.checked = true;
        this.dynamicTags.push(item);
        // this.$router.push(item.url); // 使用 Vue Router 进行页面跳转
      }
      // 更新 localStorage
      localStorage.setItem("dynamicTags", JSON.stringify(this.dynamicTags));
    }
  }
});
</script>

<style lang="scss" scoped>

</style>
