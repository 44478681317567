<script>
import {ElMessage, ElMessageBox} from "element-plus";
import {userInfo} from "@/api/pages/index";

export default {
  setup() {
    return {}
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      userInfo().then(res=>{
        localStorage.setItem('user', JSON.stringify(res.data));
      })
    },
    LogOut() {
      ElMessageBox.confirm(
          '确认退出登录?',
          '提示',
          {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          }
      )
      .then(() => {
        ElMessage({
          type: 'success',
          message: '已退出登录',
        })
        localStorage.clear(); // 清除所有缓存信息
        this.$router.push('/login')
      })
      .catch(() => {
        ElMessage({
          type: 'info',
          message: '已取消',
        })
      })
    }
  },
}
</script>

<template>
  <div class="body">
    <div style="margin-right: 10px">
      <el-avatar :size="35" src=""/>
    </div>
    <div>
      <el-dropdown>
        <div class="el-dropdown-link">
          <div class="el-dropdown-span">张三</div>
          <el-icon class="el-icon--right">
            <arrow-down/>
          </el-icon>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item>首页</el-dropdown-item>
            <el-dropdown-item>个人信息</el-dropdown-item>
            <el-dropdown-item @click="LogOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.body {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.example-showcase .el-dropdown-link {
  height: 100%;
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.el-dropdown-span {
  display: inline-block;
  max-width: 100px;
  overflow: hidden; //超出的文本隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  white-space: nowrap; //溢出不换行
}

:deep(.el-tooltip__trigger:focus-visible) {
  outline: unset;
}
</style>
