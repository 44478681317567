import api from '@/apiAxios';

export const getList = (current, size, params) => {
    return api({
        url: '/api/system/sysMenu/menu',
        method: 'get',
    })
}

export const userInfo = () => {
    return api({
        url: '/api/system/sysUser/userInfo',
        method: 'get',
    })
}


