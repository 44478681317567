import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import router from './router';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import {
    createResizeObserver,
    globalErrorHandler,
    handleUnhandledRejection
} from "@/configuration/error";
// 创建 Vue 应用实例
const app = createApp(App);
// 引入中文语言包
import zhCn from 'element-plus/es/locale/lang/zh-cn';

// 注册 Element Plus 图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}


// 设置全局错误处理
app.config.errorHandler = globalErrorHandler;
// 捕获未处理的 Promise 错误
window.addEventListener('unhandledrejection', handleUnhandledRejection);
// 创建并使用 ResizeObserver
const resizeObserver = createResizeObserver();
resizeObserver.observe(document.body); // 观察整个 body，或其他目标元素

// 使用 ElementPlus 组件库
app.use(ElementPlus, { locale: zhCn });
// 使用 Vuex 存储模块
app.use(store);
// 使用自定义的路由
app.use(router);

// 挂载 Vue 应用
app.mount('#app');
