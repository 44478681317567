<script>
import Top from "@/pages/index/leftNavigationBar/top.vue"
import RecursiveMenu from "@/pages/index/leftNavigationBar/utils/RecursiveMenu.vue" // 确保路径正确
import {getList} from "@/api/pages/index";
import { ref } from 'vue';
export default {
  components: {
    Top,
    RecursiveMenu
  },
  props: {
    dynamicTags: {
      type: Array,
      required: true
    },
  },
  setup() {
    const list = ref([]);
    return {
      list
    };
  },
  mounted() {
    this.load()
  },
  methods: {
    async load() {
      try {
        const res = await getList();
        if (res && res.code === 200) {
          this.list = res.data; // 确保赋值有效
        } else {
          console.error('获取菜单数据失败:', res.msg);
        }
      } catch (error) {
        console.error('请求出错:', error);
      }
    }
  }
}
</script>

<template>
  <div class="body">
    <el-scrollbar>
      <top/>
      <el-menu>
        <!-- default-openeds 属性控制默认展开的菜单项，这里默认展开第一个菜单 -->
        <RecursiveMenu v-if="list.length!==0" :items="list" :dynamicTags="dynamicTags"/>
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<style lang="scss" scoped>
.body {
  height: calc(100vh); /* 使 body 高度等于浏览器窗口高度 */
}
</style>
