<script>
import leftSide from "@/pages/index/header/leftSide.vue"
import RightSide from "@/pages/index/header/rightSide.vue"

export default {
  components: {
    leftSide, RightSide
  }
}
</script>

<template>
  <div class="body">
    <div>
      <!-- 左侧数据 -->
      <leftSide/>
    </div>
    <div>
      <!-- 右侧数据 -->
      <RightSide/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.body {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
</style>
