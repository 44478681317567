<template>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  setup() {
    return {}
  },
  mounted() {

  }
}
</script>

<style lang="scss">
.queryButton {
  display: flex;
}

.query {
  border: 1px rgb(220, 223, 230) solid;
  padding: 1vw;
  margin-bottom: 10px;
}

.table {
  border: 1px rgb(220, 223, 230) solid;
  padding: 1vw;
  margin-bottom: 10px;
}

.table .el-table__body, th, tr, td {
  border: 1px rgb(220, 223, 230) solid;
}

.Pagination {
  display: flex;
  justify-content: flex-end;
  border: 1px rgb(220, 223, 230) solid;
  padding: 1vw;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  flex-shrink: 0;
}

.menu-name {
  display: inline-block;
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
