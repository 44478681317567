// src/error.js

import { ElMessage } from 'element-plus';

// 全局错误处理
export const globalErrorHandler = (err, instance, info) => {
  console.error('捕获到全局错误:', err);
  ElMessage.error('发生了一个错误，请稍后再试。');
};

// 捕获未处理的 Promise 错误
export const handleUnhandledRejection = (event) => {
  console.error('捕获到未处理的 Promise 错误:', event.reason);
};

// 忽略 ResizeObserver 的警告
const originalConsoleError = console.error;
console.error = function (...args) {
  if (args[0] && typeof args[0] === 'string' && args[0].includes('ResizeObserver loop completed with undelivered notifications')) {
    return; // 忽略该警告
  }
  originalConsoleError.apply(console, args); // 继续调用原有的 console.error
};

// 控制 ResizeObserver 的触发频率
let resizeTimeout;
export const createResizeObserver = () => {
  return new ResizeObserver(entries => {
    if (resizeTimeout) return;
    resizeTimeout = setTimeout(() => {
      for (const entry of entries) {
        // 处理 resize 逻辑
      }
      resizeTimeout = null; // 重置超时
    }, 100); // 控制触发频率
  });
};
