<script>
import LeftNavigationBar from "@/pages/index/leftNavigationBar/index.vue"
import Header from "@/pages/index/header/index.vue"
import {defineAsyncComponent, ref} from 'vue';

export default {

  components: {
    LeftNavigationBar, Header
  },
  setup() {
    const dynamicTags = ref([]);
    return {
      dynamicTags,
      currentComponent: ''
    }
  },
  created() {

  },
  mounted() {
    this.load()
  },
  methods: {
    load() {
      let dynamicTagsData = localStorage.getItem("dynamicTags")
      if (dynamicTagsData !== '' && dynamicTagsData !== null) {
        this.dynamicTags = JSON.parse(dynamicTagsData);
      } else {
        this.dynamicTags = []
      }
    },
    // 处理标签删除
    handleClose(index) {
      // 获取当前删除的标签
      const removedTag = this.dynamicTags[index];
      // 删除指定索引的标签
      this.dynamicTags.splice(index, 1);
      // 如果删除的是当前选中的标签
      if (removedTag.checked) {
        // 找到前一个选中的标签的索引
        const previousIndex = index - 1;
        if (previousIndex >= 0) {
          this.dynamicTags[previousIndex].checked = true; // 将前一个标签设为选中
          // 修改 URL 而不跳转
          window.history.pushState({}, '', this.dynamicTags[previousIndex].url);
        }
      }
      // 更新 localStorage
      localStorage.setItem("dynamicTags", JSON.stringify(this.dynamicTags));
    },
    handleMenuClick(item) {
      // 先将 dynamicTags 数组中所有的 checked 设置为 false
      this.dynamicTags.forEach(tag => tag.checked = false);
      // 检查 dynamicTags 数组中是否已经包含此项
      const existingTagIndex = this.dynamicTags.findIndex(tag => tag.id === item.id);
      if (existingTagIndex !== -1) {
        // 如果存在，则将其 checked 设置为 true
        this.dynamicTags[existingTagIndex].checked = true;
        // 修改 URL 而不跳转
        window.history.pushState({}, '', item.url);
      }
      // 更新 localStorage
      localStorage.setItem("dynamicTags", JSON.stringify(this.dynamicTags));
    },
    renderComponent() {
      // 找到 checked 为 true 的标签
      const activeTag = this.dynamicTags.find(tag => tag.checked);
      // 如果找到了，则根据 url 动态加载组件
      if (activeTag) {
        window.history.pushState({}, '', activeTag.url);
        return defineAsyncComponent(() => import(`@/view${activeTag.url}.vue`));
      }else{}
      return defineAsyncComponent(() => import(`@/view/wel/index.vue`));
    },
  },
}
</script>

<template>
  <el-container class="layout-container-demo">
    <el-aside width="200px">
      <LeftNavigationBar :dynamicTags="dynamicTags"/>
    </el-aside>
    <el-container>
      <el-header>
        <Header/>
      </el-header>
      <div>
        <el-tag
            v-for="(item, index) in dynamicTags"
            :key="index"
            :disable-transitions="false"
            :type="item.checked ? 'primary' : 'info'"
            closable
            effect="dark"
            @click="handleMenuClick(item)"
            @close="handleClose(index)"
        >
          {{ item.menuName }}
        </el-tag>
      </div>
      <el-main style="padding: 10px;border: 1px rgb(220, 223, 230) solid;">
        <component :is="renderComponent()"/>
      </el-main>
    </el-container>
  </el-container>
</template>

<style scoped>

.el-tag {
  margin: 2px 4px;
  --el-tag-border-radius: none;
}

.layout-container-demo .el-header {
  position: relative;
  background-color: var(--el-color-primary-light-7);
  color: var(--el-text-color-primary);

}


.layout-container-demo .el-menu {
  border-right: none;
}

.layout-container-demo .el-main {
  padding: 0;
}

.layout-container-demo .toolbar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  right: 20px;
}
</style>
