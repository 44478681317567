import {createWebHistory , createRouter} from 'vue-router'

import login from '@/pages/login/index.vue'
import index from '@/pages/index/index.vue'

const routes = [
    {
        path: '/',
        redirect:"/index"
    },
    {
        path: '/login',
        component: login,
        hidden: true
    },
    {
        path: '/index',
        component: index,
        hidden: true
    },
    {
        path: '/:pathMatch(.*)*', // 捕获所有路径
        redirect: '/index' // 重定向到 /index
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export default router;
