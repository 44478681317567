<script>
import miscellaneous from "@/configuration/miscellaneous";

export default {
  computed: {
    miscellaneous() {
      return miscellaneous
    }
  },
  setup() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<template>
  <el-header class="header">
    {{miscellaneous.title}}
  </el-header>
</template>

<style scoped lang="scss">
.header{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
</style>
