<script>
export default {
  setup() {
    return {}
  },
  mounted() {

  },
  methods: {},
}
</script>

<template>
  <div>

  </div>
</template>

<style scoped lang="scss">

</style>
